import { createStore } from "vuex";
export default createStore({
  state: {
    isLoading: false,
    islang: false,
  },
  mutations: {
    Loading(state, payload) {
      state.isLoading = payload;
    },
    Lang(state, payload) {
      state.islang = payload;
    },
  },
  actions: {
    setLoading(context, payload) {
      context.commit("Loading", payload);
    },
    setLang(context, payload) {
      context.commit("Lang", payload);
    },
  },
  getters: {
    islang: (state) => {
      return state.islang;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
  },
});

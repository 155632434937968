<template>
  <div class="overflow-x-hidden overflow-y-hidden container">
    <div
      class="d-flex justify-content-lg-center justify-content-between align-items-center flex-lg-row flex-column-reverse h-992-100 pt-5 pt-lg-0 h-991-100"
    >
      <div class="">
        <img src="@/assets/main_nav.png" class="w-100 h-992-100" />
      </div>
      <div
        class="d-flex justify-content-center align-items-center flex-column ms-0 ms-lg-5 p-3"
      >
        <div class="mb-4 logo_img">
          <img src="@/assets/logo.png" class="w-100" />
        </div>
        <h3 class="mb-4 text-white">BT7을 방문해 주셔서 감사합니다</h3>
        <h5 class="mb-4 text-white">
          아래 버튼을 클릭하시면 원활하게 이용 가능합니다
        </h5>
        <button class="transparent-button" @click="win_open()">접속하기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      url_arr: [
        "https://www.bt-7.com/",
        "https://www.bt777.live/",
        "https://www.bt7a.com/",
        "https://www.btseven.asia/",
      ],
    };
  },
  components: {},
  computed: {},
  methods: {
    win_open: function () {
      try {
        window.open(this.url_arr[Math.floor(Math.random() * 4 + 0)], "_blank");
      } catch {
        window.open(this.url_arr[0], "_blank");
      }
    },
  },
  created() {},
  watch: {},
  mounted() {},
};
</script>
